<template>
    <div>
      <b-container class="mt-5 mb-5">
        <h1 class="fs-4 font-weight-bold mb-4">Logboek</h1>
        
        <template v-if="!logs.length">
            <b-card no-body>
            <b-card-body>
                <div class="text-center text-muted mt-4 mb-4">
                    <font-awesome-icon icon="file-invoice" class="mb-2" size="2x" />
                    <div class="text-dark fs-5">Geen logs gevonden</div>
                </div>
            </b-card-body>
            </b-card>
        </template>

        <template v-else>
            <b-card class="mb-3">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Gebruiker</th>
                            <th>Actie</th>
                            <th class="text-right">Datum</th>
                        </tr>
                    </thead>
                    <tbody>

                    </tbody>
                </table>
            </b-card>
        </template>
      </b-container>
    </div>
</template>
  
<script>
    export default {
        metaInfo: {
            title: 'Logboek',
        },
        components: {},
        data() {
            return {
                logs: []
            }
        },
        computed: {},
        methods: {},
        created() {},
    }
</script>